import React, { Component } from 'react'

export class NotFound extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div id="notfound">
                <h1>Seite nicht gefunden - Error 404</h1>
            </div>
        )
    }
}

export default NotFound
