import React, { Component } from "react";
import Parser from 'html-react-parser';


export class Header extends Component {
  render() {
    if (!this.props.data) return null;
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-8 intro-text">
                  <h1>
                    {this.props.data.title}
                  </h1>
                  <div>
                    {Parser(this.props.data.info)}
                  </div>
                  <a
                    href="/shop/covid-19-schnelltests-fuer-unternehmen/"
                    className="btn btn-custom btn-lg page-scroll"
                    id="shopButton"
                    target='_blank' rel='noreferrer'
                  >
                    {this.props.data.button}
                  </a>
                  <div>
                    {Parser(this.props.data.info2)}
                  </div>
                </div>
                {/* <div className="col-xs-12 col-md-4 intro-text">
                  <h1>
                    {this.props.data.title}
                  </h1>
                  <h1>
                    {this.props.data.subTitle}
                  </h1>
                  <h2>
                    {this.props.data.spec1}
                  </h2>
                  <h2>
                    {this.props.data.test}
                  </h2>
                </div>
                <div className="col-md-4 intro-text">
                  <h1>
                    {this.props.data.title2}
                  </h1>
                  <h1>
                    {this.props.data.subTitle2}
                  </h1>
                  <h2>
                    {this.props.data.spec2}
                  </h2>
                  <h2>
                    {this.props.data.test}
                  </h2>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-8 intro-bottom">
                  {/*  <p>
                    {this.props.data.paragraph}
                  </p> */}

                  {Parser(this.props.data.offerTitle)}


                  {/* <ul>
                    {this.props.data.offers.map((item, i) => <li key={i}>{item}</li>)}
                  </ul> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
