import React, { Component } from "react";
import Parser from 'html-react-parser';

export class Request extends Component {
    render() {
        if (!this.props.data) return null;
        return (
            <div>
                <div id="request">
                    <div className="container">
                        <div className="row">
                            <div className="section-title">
                                <h2>{this.props.data.title}</h2>
                            </div>
                            <div className="col-md-8 col-md-offset-2">
                                <p className="text-center request-bigger">
                                    <b>{this.props.data.traits}</b>
                                </p>
                                <br />
                                <h5>
                                    <b>{this.props.data.talkToUs}</b>
                                </h5>
                                <p>
                                    {this.props.data.info1}
                                </p>
                                <br />
                                <br />
                                <h5 className="text-center request-big">
                                    <b>{this.props.data.callUs}</b>
                                </h5>
                                <br />
                                {this.props.data.callUsSub.map((item, i) => (
                                    <p key={i} className="text-center">
                                        {Parser(item)}
                                    </p>
                                ))}
                                <br />
                                <p className="text-center">
                                    {this.props.data.emailTitle}
                                </p>
                                <p className="text-center">
                                    <a href={"mailto:" + this.props.data.email1}>{this.props.data.email1}</a>
                                </p>
                                <br />
                                <br />
                                <p>
                                    {this.props.data.info2Title}
                                </p>
                                <ul id="requestLi">
                                    {this.props.data.info2.map((item, i) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                </ul>
                                <div className="shopButtonWrap">
                                    <a
                                        href="/shop/covid-19-schnelltests-fuer-unternehmen/"
                                        className="btn btn-custom btn-lg page-scroll"
                                        id="shopButton"
                                        target='_blank' rel='noreferrer'
                                    >
                                        Direkt zum Shop
                                    </a>
                                </div>

                                <h5>
                                    {this.props.data.info3Title}
                                </h5>
                                <p>
                                    {this.props.data.info3}
                                </p>
                                <p className="text-center">
                                    <a href={"mailto:" + this.props.data.email2}>{this.props.data.email2}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

export default Request;
