import React, { Component } from "react";

export class Download extends Component {
  render() {
    if (!this.props.data) return null;
    return (
      <div id="download">
        <div className="container">
          <div className="section-title col-md-8 col-md-offset-2">
            <h2 className="text-center">{this.props.data.title}</h2>
            <br />
            <br />
            <br />
            {/* <p className="text-center download-bigger">
              <a href={`${process.env.PUBLIC_URL}/download/${this.props.data.file}`} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-download" style={{ marginRight: "1em" }}></i>
                {this.props.data.info}
              </a>
            </p> */}
            <br />
            <br />
            <br />
            <p>
              {this.props.data.info2}
            </p>
            <br />
            <p>
              {this.props.data.request}
            </p>
            <br />
            <p className="text-center">
              <a href={"mailto:" + this.props.data.email}>{this.props.data.email}</a>
            </p>
            <br />
            <p>
              <b>{this.props.data.thankYou}</b>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Download;
