import React, { Component } from 'react'
import Navigation from './components/navigation';
import Footer from './components/footer';
import Contact from './components/contact';
import Imprint from './components/imprint';
import Privacy from './components/privacy';
import Main from './components/main';
import JsonData from './data/data.json';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Products from './components/products';
import NotFound from './components/404';
import { CookieBanner } from './cookie/index';
import TagManager from 'react-gtm-module';
import {GTag} from '@deptno/gtag-react'
import Newsletter from './components/newsletter';


export class App extends Component {
  state = {
    landingPageData: {},
    language: "DE",
    gtmLoaded: false
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  getlandingPageData() {
    this.setState({
      landingPageData: JsonData[this.state.language],
    })
  }

  changeLanguage = lang => {
    this.setState({
      language: lang,
      landingPageData: JsonData[lang],
    })
  }

  loadGoogleAnalytics = () => {
    if(this.state.gtmLoaded) {
      return;
    }
    const tagManagerArgs = {
        gtmId: 'GTM-K99VRTZ'
    }
    TagManager.initialize(tagManagerArgs);
    this.setState({
      gtmLoaded: true
    })
  }
  
  render() {
    return (
      <div>
        <div>
          <CookieBanner
            message="Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern."
            wholeDomain={true}
            policyLink={"/shop/datenschutzerklaerung"}
            privacyPolicyLinkText={"Datenschutzerklärung"}
            necessaryOptionText={"Essenziell"}
            statisticsOptionText={"Statistik"}
            showPreferencesOption={false}
            showMarketingOption={false}
            onAcceptStatistics = {this.loadGoogleAnalytics}
            acceptButtonText={"Ausgewählte akzeptieren"}
            acceptAllButtonText={"Alle akzeptieren"}
          />
        </div>
        <Router>
          <Navigation data={this.state.landingPageData.nav} changeLanguage={this.changeLanguage} />
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/products`}
              render={() => <Products antigen={this.state.landingPageData.products} antibody={this.state.landingPageData.products2} />} />
            <Route exact path={`${process.env.PUBLIC_URL}/contact`}
              render={() => <Contact data={this.state.landingPageData.contact} />} />
            {/* <Route exact path={`${process.env.PUBLIC_URL}/imprint`}
              render={() => <Imprint data={this.state.landingPageData.imprint} />} />
            <Route exact path={`${process.env.PUBLIC_URL}/privacy`}
              render={() => <Privacy data={this.state.landingPageData.privacy} />} /> */}
            <Route exact path={`${process.env.PUBLIC_URL}/newsletter`}
            render={() => <Newsletter id='newsletter' />} />
            <Route exact path={`${process.env.PUBLIC_URL}`}
              render={() => <Main landingPageData={this.state.landingPageData} />} />
            <Route component={NotFound} />
            <Redirect exact path={`${process.env.PUBLIC_URL}/`} to='' />
          </Switch>
          
          <Footer data={this.state.landingPageData.footer} />
        </Router>
        {this.state.gtmLoaded ? <GTag id={'UA-185479125-1'} /> : ''}
      </div>
    )
  }
}

export default App;
