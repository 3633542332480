import React, { Component } from 'react'
import Product from './product';

export class Products extends Component {

    render() {
        if (!this.props.antigen) return null;
        return (
            <div id="products">
                <div className="container">
                    <h2>COVID-19 ANTIGEN Schnelltest-Kits</h2>
                    {this.props.antigen.map((item, id) =>
                        <Product key={id} data={item} id={id}></Product>
                    )}
                    {/*  <h2>COVID-19 Antikörper Schnelltest-Kits</h2>
                {this.props.antibody.map((item, id) =>
                    <Product key={id} data={item} id={id}></Product>
                )} */}
                </div>

            </div>
        )
    }
}

export default Products
