import React, { Component } from "react";

export class about extends Component {
  render() {
    if (!this.props.data) return null;
    return (
      <div id="about" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>{this.props.data.title}</h2>
          </div>
          <div className="row about-text">
            <div className="col-md-6 col-md-offset-3 list-style">
              {this.props.data.text}
              <br />
              <br />
              <br />
              <ul id="aboutLi">
                {this.props.data.list.map((item, i) =>
                  <li key={i}>{item}</li>
                )}
              </ul>
            </div>
          </div>
          <div className="row mt-5 aboutIcons">
            {this.props.data.features.map((item, i) => (
              <div key={i} className="col-xs-12 col-md-4">
                {" "}
                <i className={item.icon}></i>
                <h3>{item.title}</h3>
                <ul>
                  {item.text.map((item2, i2) => (
                    <li key={i2}>{item2}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default about;
