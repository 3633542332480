import React, { Component } from "react";
import Parser from 'html-react-parser';


export class Contact extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  render() {
    if (!this.props.data) return null;
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>{this.props.data.title}</h2>
                  <br />
                  {this.props.data.info.map((item, i) => {
                    if (item.length > 0) {
                      return <p key={i}>{Parser(item)}</p>
                    } else {
                      return <br />
                    }
                  }
                  )}
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder={this.props.data.placeholderName}
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder={this.props.data.placeholderMail}
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder={this.props.data.placeholderMessage}
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <div>{this.props.data.disclaimer}</div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    {this.props.data.button}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>{this.props.data.sideTitle}</h3>
              </div>
              <div className="contact-item">
                <p>
                  <b>{this.props.data ? this.props.data.companyName : "loading"}<br /></b>
                  {this.props.data ? this.props.data.companyAddress : "loading"}<br />
                  {this.props.data ? this.props.data.companyAddress2 : "loading"}<br />
                  <br />
                  <i className="fa fa-phone"></i>
                  {" "}
                  <a href={this.props.data ? 'tel:' + this.props.data.companyPhone : "loading"}>{this.props.data ? this.props.data.companyPhone : "loading"}</a>
                  <br />
                  <i className="fa fa-fax"></i>
                  {" "}
                  {this.props.data ? this.props.data.companyFax : "loading"}
                  <br />
                  <i className="fa fa-envelope-o"></i>
                  {" "}
                  <a href={this.props.data ? 'mailto:' + this.props.data.companyMail : "loading"}>{this.props.data ? this.props.data.companyMail : "loading"}</a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div >
    );
  }
}

export default Contact;
