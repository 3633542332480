import React, { Component } from "react";
import Newsletter from "./newsletter";

export class Newsletter2 extends Component {
  render() {
    if (!this.props.data) return null;
    return (
      <div id="news">
        <div className="container">
            <Newsletter />
        </div>
      </div>
    );
  }
}

export default Newsletter2;
