import React, { Component } from 'react';
import Header from './header';
import About from './about';
import Download from './download';
import Request from './request';
/* import Features from './features'; */
import Productinfo from './productinfo';
import Newsletter2 from './newsletter2';


export class main extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Header data={this.props.landingPageData.header} />
                {/* <Features data={this.props.landingPageData.features} /> */}
                <Productinfo data={this.props.landingPageData.productinfo} />
                <Newsletter2 data={this.props.landingPageData.newsletter} />
                <About data={this.props.landingPageData.about} />
                <Request data={this.props.landingPageData.request} />
            </div>
        )
    }
}

export default main
