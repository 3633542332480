import React, { Component } from 'react';

export class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputAnrede: '',
            inputVorname: '',
            inputNachname: '',
            inputEmail: '',
            inputPlz: '',
            inputTel: ''
        }
    }

    handleAnrede = e =>  {
        this.setState({
            inputAnrede: e.target.value
        })
    }

    handleVorname = e =>  {
        this.setState({
            inputVorname: e.target.value
        })
    }

    handleNachname = e =>  {
        this.setState({
            inputNachname: e.target.value
        })
    }

    handleEmail = e =>  {
        this.setState({
            inputEmail: e.target.value
        })
    }

    handlePlz = e =>  {
        this.setState({
            inputPlz: e.target.value
        })
    }

    handleTel = e =>  {
        this.setState({
            inputTel: e.target.value
        })
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <div className="section-title col-md-8 col-md-offset-2 newsletter">
                    <h2 className="text-center">Newsletter</h2>
                    <br />
                    <form class="layout_form cr_form cr_font" action="https://seu2.cleverreach.com/f/295639-295940/wcs/" method="post" target="_blank">
                        <div class="cr_body cr_page cr_font formbox">
                            <div class="non_sortable" style={{ textAlign: "left" }}></div>
                            <div class="editable_content" style={{ textAlign: "left" }}>
                                <div id="6548978" rel="select" class="cr_ipe_item ui-sortable">
                                    <label for="select_6548978" class="itemname">Anrede</label> 
                                    <select id="select_6548978" class="cr_font" name="1094116" style={{ width: "100%" }} onChange={this.handleAnrede}>
                                        <option value="1" ></option>
                                        <option value="Frau">Frau</option>
                                        <option value="Herr">Herr</option>
                                    </select>
                                </div>
                                <div id="6548891" rel="text" class="cr_ipe_item ui-sortable" style={{ marginBottom: "0px" }}>
                                    <label for="text6548891" class="itemname">Vorname</label> 
                                    <input id="text6548891" name="1094117" type="text" value={this.state.inputVorname} style={{ width: "100%" }} onChange={this.handleVorname}/>
                                </div>
                                <div id="6548892" rel="text" class="cr_ipe_item ui-sortable musthave" style={{ marginBottom: "0px" }}>
                                    <label for="text6548892" class="itemname">Nachname*</label> 
                                    <input id="text6548892" name="1094118" type="text" value={this.state.inputNachname} style={{ width: "100%" }} onChange={this.handleNachname}/>
                                </div>
                                <div id="6534153" rel="email" class="cr_ipe_item ui-sortable musthave">
                                    <label for="text6534153" class="itemname">E-Mail*</label> 
                                    <input id="text6534153" name="email" value={this.state.inputEmail} type="text" style={{ width: "100%" }} onChange={this.handleEmail}/>
                                </div>
                                <div id="6548931" rel="text" class="cr_ipe_item ui-sortable" style={{ marginBottom: "0px" }}>
                                    <label for="text6548931" class="itemname">PLZ</label> 
                                    <input id="text6548931" name="1094120" type="text" value={this.state.inputPlz} style={{ width: "100%" }} onChange={this.handlePlz}/>
                                </div>
                                <div id="6548975" rel="text" class="cr_ipe_item ui-sortable" style={{ marginBottom: "0px" }}>
                                    <label for="text6548975" class="itemname">Mit der Bitte um Rückruf:</label> 
                                    <input id="text6548975" name="1094119" type="text" value={this.state.inputTel} style={{ width: "100%" }} onChange={this.handleTel}/>
                                </div>
                                <div id="6534155" rel="button" class="cr_ipe_item ui-sortable submit_container" style={{ textAlign: "center" }}>
                                    <button type="submit" class="cr_button">Anmelden</button>
                                </div>
                            </div>  
                        <noscript><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript>
                        </div>
                    </form>
                    <p>
                        <b>Vielen Dank für Ihr Interesse.</b>
                    </p>
                </div>
            </div>
        )
    }
}

export default Newsletter
