import React, { Component } from 'react';
import JsxParser from 'react-jsx-parser';
import { NavHashLink as NavLink, HashLink as Link } from 'react-router-hash-link';

export class Productinfo extends Component {

    render() {
        if (!this.props.data) return null;

        return (
            <div id="productinfo">
                <div className="container">
                    <h2>COVID-19 ANTIGEN Schnelltest-Kits</h2>
                    <div id="productinfotext">
                        <JsxParser components={{ NavLink, Link }}
                            jsx={this.props.data} />
                    </div>
                    <div className="shopButtonWrap">
                        <a
                            href="/shop/covid-19-schnelltests-fuer-unternehmen/"
                            className="btn btn-custom btn-lg page-scroll"
                            id="shopButton"
                            target='_blank' rel='noreferrer'
                        >
                            Direkt zum Shop
                        </a>
                    </div>
                </div>
            </div >
        )
    }
}

export default Productinfo
