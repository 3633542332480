import React, { Component } from 'react';
import Parser from 'html-react-parser';

export class Product extends Component {

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render() {
        if (!this.props.data) return null;
        return (
            <div className="product" id={this.props.data.id}>
                {this.props.id > 0 ? <hr /> : ""}
                <div className="row">
                    <div className="col-xs-12 col-md-4 productName">
                        <h5>{this.props.data.name}</h5>
                        {this.props.data.titleImage ?
                            <a href={this.props.data.shopLink} target="_blank" rel="noopener noreferrer" className="productImg">
                                <img src={this.props.data.titleImage} className="" alt="" />
                            </a> : ""}
                    </div>
                    {/*  <ul className="col-xs-12 col-md-4 productProperties">
                        <h5>Merkmale</h5>
                        {this.props.data.properties.map((property, id) =>
                            <li key={id}>{property}</li>
                        )}
                    </ul> */}
                    {/* <ul className="col-xs-12 col-md-4 productPrices">
                        <h5>Preise (Bestellmenge / Nettopreis)</h5>
                        {this.props.data.prices.map((price, id) =>
                            <li key={id}>{price}</li>
                        )}
                        <p>Alle Preise netto, zzgl. MwSt., zzgl. Versandkosten</p>
                        <p>Lieferzeit: 2-3 Werktage</p>
                        <p>Anfrage Bestellung / Bestellung unter: <a href="mailto:order@matchpharm.de">order@matchpharm.de</a> oder <a href="tel:+498920930215">+49 (0) 89 2093 0215</a></p>
                    </ul> */}
                    {/* <div className="col-xs-12 col-md-4">
                        {this.props.data.shopLink ?
                            <div>
                                <h5>Bestellung</h5>
                                <a href={this.props.data.shopLink}>► DIREKT IM SHOP BESTELLEN</a>
                            </div>
                            : ""}
                    </div> */}
                    <div className="col-xs-12 col-md-8 productDescription">
                        <h5>Produktbeschreibung</h5>
                        <div>{Parser(this.props.data.description)}</div>
                        <br />
                        <ul className="productProperties">
                            <h5>Merkmale</h5>
                            {this.props.data.properties.map((property, id) =>
                                <li key={id}>{Parser(property)}</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    {/*   <div className="col-xs-12 col-md-4 productImg">
                        {this.props.data.images[0] ? <h5>Weitere Produktbilder</h5> : ""}
                        {this.props.data.images[0] ? this.props.data.images.map((image, id) =>
                            <a key={id} href={image} target="_blank" rel="noopener noreferrer">
                                <img src={image} className="" alt="" />
                            </a>) : ""
                        }
                    </div> */}
                </div>
                <div className="row productDownload">
                    {/* <div className="col-xs-12 col-md-4">
                        <h5>Download Dokumente:</h5>
                        <ul>
                            {this.props.data.download.map((item, id) =>
                                <li key={id}>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>
                                </li>
                            )}
                        </ul>
                    </div> */}
                    <div className="col-xs-12 col-md-8 col-md-offset-4">
                        {this.props.data.shopLink ?
                            <div>
                                <h5>Bestellung</h5>
                                <a href={this.props.data.shopLink} id="shopButton"><b>► DIREKT IM SHOP BESTELLEN</b></a>
                            </div>
                            : ""}
                    </div>
                </div>
            </div >
        )
    }
}

export default Product
