import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

export class Navigation extends Component {

  onClickChangeLanguage = () => {
    this.props.changeLanguage(this.props.data.nav5alt);
  }

  render() {
    if (!this.props.data) return null;
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#collapse"
            >
              <span className="sr-only">{this.props.data.toggle}</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand page-scroll" href={`${process.env.PUBLIC_URL}/#`}>
              <img id='logo' src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="MatchPHARM" />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href={`${process.env.PUBLIC_URL}/shop/covid-19-schnelltests-fuer-unternehmen/`} data-toggle="collapse" data-target=".in">
                  {this.props.data.nav0}
                </a>
              </li>
              <li>
                <NavLink tag={Link} to={`${process.env.PUBLIC_URL}/products`} data-toggle="collapse" data-target=".in">
                  {this.props.data.nav1}
                </NavLink>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/#news`} className="page-scroll" data-toggle="collapse" data-target=".in">
                  {this.props.data.nav2}
                </a>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/#about`} className="page-scroll" data-toggle="collapse" data-target=".in">
                  {this.props.data.nav3}
                </a>
              </li>
              <li>
                <a href={`${process.env.PUBLIC_URL}/#request`} className="page-scroll" data-toggle="collapse" data-target=".in">
                  {this.props.data.nav4}
                </a>
              </li>
              {/* <li> */}
              {/* eslint-disable-next-line */}
              {/* <a href="javascript:void(0)" onClick={this.onClickChangeLanguage} className="page-scroll" data-toggle="collapse" data-target=".in">
                  <img id='flag' src={this.props.data.nav5} alt={this.props.data.nav5alt} />
                </a>
              </li> */}
            </ul>
          </div>
        </div >
      </nav >
    );
  }
}

export default Navigation;
