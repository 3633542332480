import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";

export class Footer extends Component {

    render = () => {
        if (!this.props.data) return null;

        return (
            <div id="footer">
                <div className="container footer-left">
                    <p>
                        <NavLink tag={Link} to={`${process.env.PUBLIC_URL}/contact`} style={{ marginLeft: "2em", marginRight: "2em" }}>
                            {this.props.data.contact}
                        </NavLink>
                        <a href='/shop/agb' style={{ marginLeft: "2em", marginRight: "2em" }} target='_blank' rel='noreferrer'>
                            {this.props.data.agb}
                        </a>
                        <a href='/shop/impressum' style={{ marginLeft: "2em", marginRight: "2em" }} target='_blank' rel='noreferrer'>
                            {this.props.data.imprint}
                        </a>
                        <a href='/shop/datenschutzerklaerung' style={{ marginLeft: "2em" }} target='_blank' rel='noreferrer'>
                            {this.props.data.privacy}
                        </a>
                    </p>
                </div>
                <div className="footer-right">
                    <p style={{ marginRight: "2em" }}>
                        &copy; MatchPHARM 2020
                    </p>
                </div>
            </div>
        )
    }
}

export default Footer;