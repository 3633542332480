import React, { Component } from 'react'

export class privacy extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }
    render() {
        if (!this.props.data) return null;
        return (
            <div id="privacy">
                <div className="container">
                    <div className="section-title">
                        <br />
                        <br />
                        <h2>{this.props.data.title}</h2>
                        <br />
                        <br />
                        <br />
                        <h4>{this.props.data.subTitle1}</h4>
                        {this.props.data.content1.map((item, i) =>
                            <span key={i}>{item}<br /></span>
                        )}
                        <br />
                        <h4>{this.props.data.subTitle2}</h4>
                        {this.props.data.content2a.map((item, i) =>
                            <span key={i}>{item}<br /></span>
                        )}
                        <ul>
                            {this.props.data.list2a.map((item, i) =>
                                <li key={i}>{item}</li>
                            )}
                        </ul>
                        {this.props.data.content2a2}
                        <ul>
                            {this.props.data.list2a2.map((item, i) =>
                                <li key={i}>{item}</li>
                            )}
                        </ul>
                        {this.props.data.content2a3}
                        <br />
                        <br />
                        {this.props.data.content2b.map((item, i) =>
                            <span key={i}>{item}<br /></span>
                        )}
                        <br />
                        <h4>{this.props.data.subTitle3}</h4>
                        {this.props.data.content3}
                        <ul>
                            {this.props.data.list3.map((item, i) =>
                                <li key={i}>{item}</li>
                            )}
                        </ul>
                        <br />
                        <h4>{this.props.data.subTitle4}</h4>
                        {this.props.data.content4.map((item, i) =>
                            <span key={i}>{item}<br /></span>
                        )}
                        <br />
                        <h4>{this.props.data.subTitle5}</h4>
                        {this.props.data.content5}
                        <br />
                        <ul>
                            {this.props.data.list5.map((item, i) =>
                                <li key={i}>{item}</li>
                            )}
                        </ul>
                        <br />
                        <h4>{this.props.data.subTitle6}</h4>
                        {this.props.data.content6}
                        <br />
                        <br />
                        <h4>{this.props.data.subTitle7}</h4>
                        {this.props.data.content7.map((item, i) =>
                            <span key={i}>{item}<br /></span>
                        )}
                        <br />
                        <h4>{this.props.data.subTitle8}</h4>
                        {this.props.data.content8.map((item, i) =>
                            <span key={i}>{item}<br /></span>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default privacy
